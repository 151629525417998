import React from 'react';
import { Admin, Resource } from 'react-admin';
// Providers
import authProvider from './authProvider';
import dataProvider from './dataProvider';
// Layout Related
import Layout from './layout/Layout';
import Login from './layout/Login';
import theme from './layout/theme.js';
// Modules
// import Dashboard from './components/dashboard';
import Dependencies from './components/dependencies';
import Plugins from './components/plugins';
import Servers from './components/servers';
import Licenses from './components/licenses';

function App() {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider} layout={Layout} theme={theme} loginPage={Login} disableTelemetry requireAuth>
      <Resource name="plugins" {...Plugins} />
      <Resource name="dependencies" {...Dependencies} />
      <Resource name="servers" {...Servers} />
      <Resource name="licenses" {...Licenses} />
    </Admin>
  );
}

export default App;
