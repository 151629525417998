import _ from 'lodash';
import licenseTypes from './LicenseTypes';

function LicenseType(props) {
  const licenseType = _.find(licenseTypes, { id: props.typeId });

  return <span>{licenseType.name}</span>;
}

export default LicenseType;
