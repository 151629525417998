import React from 'react';
import { List, Datagrid, SearchInput, NumberField, TextField, DateField } from 'react-admin';

const filters = [<SearchInput source="q" alwaysOn />];

const ServerList = props => {
  return (
    <List {...props} perPage={25} filters={filters} title="Registered Servers">
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="hostname" label="FQDN" />
        <TextField source="cwaVersion" label="CWA Version" />
        <TextField source="dbVersion" label="MySQL" />
        <DateField source="lastContact" label="Last Contact" />
        <DateField source="createdAt" label="Date Registered" />
      </Datagrid>
    </List>
  );
};

export default ServerList;
