import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import licenseTypes from '../common/LicenseTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EventList from './LicenseEditAside';

import { Button, DateField, Edit, Labeled, SimpleForm, TextField, TextInput, SelectInput, DateTimeInput, useRecordContext, useNotify } from 'react-admin';
import { Alert, AlertTitle, Box, Card, Grid, Typography } from '@mui/material';

const LicenseEdit = props => {
  const notify = useNotify();

  const onSuccess = data => {
    notify(
      <Alert severity="success" color="success" variant="standard">
        <AlertTitle>License Updated</AlertTitle>license update was successfully
      </Alert>,
      { anchorOrigin: { vertical: 'top', horizontal: 'right' } }
    );
  };

  return (
    <Edit title="View License" component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <EditForm />
    </Edit>
  );
};

const LastResponse = () => {
  const record = useRecordContext();

  if (record) {
    let responseArray = record.lastResponse.split('|');
    let licenseState = undefined;
    let severity = undefined;

    if (responseArray.length > 1) {
      licenseState = responseArray[1];
      severity = licenseState.toLowerCase() === 'active' ? 'success' : 'error';
    } else {
      severity = 'error';
    }

    return <Alert severity={severity}>{responseArray.length === 1 ? record.lastResponse.replace('Error Code: 2 (', '').replace(')', '') : licenseState}</Alert>;
  }
};

const LicenseUsage = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      {record.agentsUsed} of {record.agentsLicensed}
    </span>
  ) : null;
};

const LicenseTitle = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      <strong>{record.plugin ? record.plugin.name : 'Unknown'}</strong> for {record.server ? record.server.name : 'Unknown'}
    </span>
  ) : null;
};

// const Spacer = () => <Box m={1}>&nbsp;</Box>;

const EditForm = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={10}>
                  <Typography variant="h6" gutterBottom>
                    <LicenseTitle label="Details" />
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button size="medium" alignIcon="left" label="Go back" onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectInput source="licenseType" label="License Type" variant="standard" choices={licenseTypes} sx={{ width: 250 }} />
                </Grid>
                <Grid item xs={4}>
                  <TextInput source="agentsLicensed" label="Licensed Agents" variant="standard" sx={{ width: 250 }} />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInput source="licenseExpire" label="Expiration Date" variant="standard" sx={{ width: 250 }} />
                </Grid>
              </Grid>
              <Typography variant="h6" gutterBottom>
                Additional Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Labeled>
                    <LicenseUsage label="Agent Details" />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <TextField source="server.cwaVersion" label="Automate Version" />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <TextField source="server.dbVersion" label="MySQL Version" />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <TextField source="installedVersion" label="Installed Version" />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <DateField source="createdAt" label="Registration" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <DateField source="lastContact" label="Last Communication" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Last Response
                  </Typography>
                  <LastResponse label="Last Response" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default LicenseEdit;
