import DependencyIcon from '@mui/icons-material/Collections';
import DependencyList from './DependencyList';
// import DependencyEdit from './DependencyEdit';
// import DependencyCreate from './DependencyCreate';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: DependencyList,
  //   create: DependencyCreate,
  //   edit: DependencyEdit,
  //   icon: DependencyIcon,
};
