import * as React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Link, useRecordContext, DateField } from 'react-admin';
import { TableCellRight } from '../common/TableCellRight';
import LicenseType from '../common/LicenseType';

const Licenses = () => {
  const record = useRecordContext();

  //   const licenseIds = record ? record.licenses.map(license => license.id) : [];

  if (!record) return null;

  return (
    <Table size="small" sx={{ maxWidth: '94%' }}>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '33%' }}>Plugin</TableCell>
          <TableCell style={{ width: '33%' }}>Type</TableCell>
          <TableCellRight style={{ width: '33%' }}>Expiration</TableCellRight>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.licenses.map(license => (
          <TableRow key={license.id}>
            <TableCell>
              <Link to={`/licenses/${license.id}`}>{license.pluginName}</Link>
            </TableCell>
            <TableCell>
              <LicenseType typeId={license.licenseType} />
            </TableCell>
            <TableCellRight>
              <DateField record={license} source="licenseExpire" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'UTC' }} />
            </TableCellRight>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Licenses;
