import React from 'react';
import { List, Datagrid, SearchInput, NumberField, TextField, BooleanField } from 'react-admin';

const filters = [<SearchInput source="name" alwaysOn />];

const PluginList = props => {
  return (
    <List {...props} filters={filters} title="List of Plugins">
      <Datagrid rowClick="edit">
        <NumberField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="version" label="Version" />
        <TextField source="cwaMinVersion" label="Min. CWA Version" />
        <TextField source="cwaMaxVersion" label="Max. CWA Version" />
        <BooleanField source="isRemote" label="Remote Agent" />
        <BooleanField source="limitByVersion" label="Limit by Version" />
        <BooleanField source="published" label="Published" />
      </Datagrid>
    </List>
  );
};

export default PluginList;
