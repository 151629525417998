import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNotify, useRedirect, useRefresh, Button, Edit, TextInput, NullableBooleanInput, TabbedForm, ArrayInput, SimpleFormIterator, AutocompleteInput, ReferenceInput } from 'react-admin';
import { Box, Card, Grid, Typography } from '@mui/material';

const PluginEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Record updated successfully', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    redirect('/plugins');
    refresh();
  };

  return (
    <Edit title="Edit Plugin" component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <EditForm />
    </Edit>
  );
};

const EditForm = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="50em">
      <Card>
        <TabbedForm>
          <TabbedForm.Tab label="Plugin Details">
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Typography variant="h6" gutterBottom>
                  General Details
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button size="medium" alignIcon="left" label="Go back" onClick={() => navigate(-1)}>
                  <ArrowBackIcon />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextInput source="name" label="Name" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="author" label="Author" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="description" label="Description" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <NullableBooleanInput source="enforceLicense" label="License Enforcement" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <NullableBooleanInput source="published" label="Published" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  ConnectWise Automate Specifics
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextInput source="fileName" label="Filename" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextInput source="guid" label="GUID" variant="standard" fullWidth />
              </Grid>

              <Grid item xs={4}>
                <TextInput source="cwaMinVersion" label="Min. CWA Version" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextInput source="cwaMaxVersion" label="Max. CWA Version" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <NullableBooleanInput source="isRemote" label="Remote Agent Plugin" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Stable Release
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextInput source="downloadUrl" label="Download URL" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={2}>
                <TextInput source="version" label="Version" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="md5Hash" label="MD5 Hash" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Beta Release
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextInput source="downloadBetaUrl" label="Download URL" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={2}>
                <TextInput source="versionBeta" label="Version" variant="standard" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="md5HashBeta" label="MD5 Hash" variant="standard" fullWidth />
              </Grid>
            </Grid>
          </TabbedForm.Tab>
          <TabbedForm.Tab label="Dependencies">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <ArrayInput source="dependencies" label="">
                  <SimpleFormIterator
                    sx={{
                      '& .RaSimpleFormIterator-line': {
                        borderBottomWidth: 0,
                        marginTop: 0,
                        marginBottom: 0,
                      },
                    }}
                    getItemLabel={index => `#${index + 1}`}
                    inline
                    fullWidth
                    disableReordering
                    disableClear
                  >
                    <ReferenceInput source="id" reference="dependencies" perPage={999}>
                      <AutocompleteInput label="Dependency" optionText="filename" optionValue="id" fullWidth variant="standard" disableClearable />
                    </ReferenceInput>
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
            </Grid>
          </TabbedForm.Tab>
        </TabbedForm>
      </Card>
    </Box>
  );
};
export default PluginEdit;
