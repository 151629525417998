import inMemoryJWT from './inMemoryJWT';

const apiUrl = process.env.REACT_APP_APIURL;

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    inMemoryJWT.setRefreshTokenEndpoint(`${apiUrl}/auth/refresh`);

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ accessToken, accessTokenLifetime }) => {
        return inMemoryJWT.setToken(accessToken, accessTokenLifetime);
      });
  },

  logout: () => {
    const request = new Request(`${apiUrl}/auth/logout`, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    inMemoryJWT.ereaseToken();
    return fetch(request).then(() => '/login');
  },

  checkAuth: () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return inMemoryJWT.waitForTokenRefresh().then(p => {
          inMemoryJWT.getToken() ? resolve() : reject();
        });
      }, 500);
    });
  },

  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      inMemoryJWT.ereaseToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    return inMemoryJWT.waitForTokenRefresh().then(() => {
      return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
    });
  },

  getIdentity: () => {},
};

export default authProvider;
