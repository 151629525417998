import * as React from 'react';
import { DateField, Edit, Labeled, SimpleForm, TextField, TextInput, useRecordContext, useNotify } from 'react-admin';
import { Alert, AlertTitle, Card, Box, Grid, Typography } from '@mui/material';

import Licenses from './Licenses';

const ServerEdit = props => {
  const notify = useNotify();

  const onSuccess = data => {
    notify(
      <Alert severity="success" color="success" variant="standard">
        <AlertTitle>Server Updated</AlertTitle>server update was successfully
      </Alert>,
      { anchorOrigin: { vertical: 'top', horizontal: 'right' } }
    );
  };

  return (
    <Edit title="View Server" component="div" mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <EditForm />
    </Edit>
  );
};

const LicenseUsage = () => {
  const record = useRecordContext();
  return record ? (
    <span>
      {record.agentsUsed} of {record.agentsLicensed}
    </span>
  ) : null;
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const EditForm = () => {
  return (
    <Box maxWidth="50em">
      <Card>
        <SimpleForm>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Server Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Labeled source="name">
                    <TextField source="name" label="Company Name" variant="standard" fullWidth />
                  </Labeled>
                </Grid>
                <Grid item xs={6}>
                  <Labeled source="hostname">
                    <TextField source="hostname" label="FQDN" variant="standard" fullWidth />
                  </Labeled>
                </Grid>
              </Grid>
              <Spacer />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput source="friendlyName" label="Friendly Name" variant="standard" sx={{ width: 250 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput source="cdkeyHash" label="Automate License Hash" variant="standard" sx={{ width: 325 }} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput source="ipAddress" label="IP Address" variant="standard" sx={{ width: 250 }} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput source="token" label="Token" variant="standard" sx={{ width: 325 }} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Labeled>
                    <LicenseUsage label="Agent Details" />
                  </Labeled>
                </Grid>
                <Grid item xs={3}>
                  <Labeled>
                    <TextField source="cwaVersion" label="Automate Version" />
                  </Labeled>
                </Grid>
                <Grid item xs={3}>
                  <Labeled>
                    <TextField source="dbVersion" label="MySQL Version" />
                  </Labeled>
                </Grid>
                <Grid item xs={3}>
                  <Labeled>
                    <TextField source="ipAddressLast" label="Last IP Address" />
                  </Labeled>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Licenses
              </Typography>
              <Licenses />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Communications
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Labeled>
                    <DateField source="lastContact" label="Last Communication" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <DateField source="createdAt" label="Registered" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                  </Labeled>
                </Grid>
                <Grid item xs={4}>
                  <Labeled>
                    <DateField source="updatedAt" label="Last Updated" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                  </Labeled>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      </Card>
    </Box>
  );
};
export default ServerEdit;
