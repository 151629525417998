import React from 'react';
import { List, Datagrid, SearchInput, NumberField, TextField, DateField } from 'react-admin';

const filters = [<SearchInput source="name" alwaysOn />];

const DependencyList = props => {
  return (
    <List {...props} perPage={25} filters={filters} title="Dependencies">
      <Datagrid rowClick="show">
        {/* <NumberField source="id" /> */}
        <TextField source="filename" label="Filename" />
        <TextField source="version" label="Version" />
        <TextField source="depType" label="Type" />
        <TextField source="location" label="Location" />
        <DateField source="updatedAt" label="Updated" />
        <DateField source="createdAt" label="Created" />
      </Datagrid>
    </List>
  );
};

export default DependencyList;
