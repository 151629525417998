import icon from '@mui/icons-material/Computer';
import list from './ServerList';
import edit from './ServerEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  edit: edit,
};
