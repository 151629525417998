import icon from '@mui/icons-material/Extension';
import list from './PluginList';
import edit from './PluginEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: icon,
  list: list,
  edit: edit,
};
