import React from 'react';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';

import { List, Datagrid, SearchInput, NumberField, TextField, DateField } from 'react-admin';

const filters = [<SearchInput source="q" alwaysOn variant="standard" />];

const serverListRowStyle = (record, index) => {
  if (record.lastResponse.toLowerCase().includes('active'))
    return {
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.lastResponse.toLowerCase().includes('expired'))
    return {
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  if (record.lastResponse.toLowerCase().includes('error'))
    return {
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
};

const ServerList = props => {
  return (
    <List {...props} perPage={25} filters={filters} title="Plugin Licenses">
      <Datagrid
        rowClick="edit"
        rowStyle={serverListRowStyle}
        sx={{
          '& .RaDatagrid-thead': {
            borderLeftColor: 'transparent',
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
          },
          '& .column-comment': {
            maxWidth: '18em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <NumberField source="id" />
        <TextField source="serverName" label="Server" />
        <TextField source="pluginName" label="Plugin" />
        <TextField source="installedVersion" label="Plugin Version" />
        <DateField source="licenseExpire" label="Expiration Date" options={{ day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' }} />
        <DateField source="createdAt" label="Registration Date" options={{ day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC' }} />
        <DateField source="lastContact" label="Last Contact" showTime options={{ day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric' }} />
      </Datagrid>
    </List>
  );
};

export default ServerList;
